.checkbox-wrapper-16 *,
.checkbox-wrapper-16 *:after,
.checkbox-wrapper-16 *:before {
  box-sizing: border-box;
}

.checkbox-wrapper-16 .checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  /* position: absolute; */
  white-space: nowrap;
  width: 1px;
}
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile {
  border-color: #319B42;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #319B42;
  border: 2px solid;
}
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #319B42;
  border-color: #319B42;
  /* margin-top: 7.5px; */
}
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-wrapper-16 .checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: #319B42;
}
.checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile {
  border-color: #319B42;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #319b433d;
}
.checkbox-wrapper-16 .checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-wrapper-16 .checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 'fit-content';
  min-height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid #319b43a8;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  padding-left: 2em;
  padding-right: 1em;
  /* margin-bottom: -1em; */
  margin-top: -12px;
}
.checkbox-wrapper-16 .checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #319b43a8;
  background-color: #fff;
  border-radius: 50%;
  /* top: 0.25rem; */
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.checkbox-wrapper-16 .checkbox-tile:hover {
  border-color: #319b43a8;
}
.checkbox-wrapper-16 .checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-wrapper-16 .checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}
.checkbox-wrapper-16 .checkbox-icon svg {
  width: 3rem;
  height: 3rem;
}

.checkbox-wrapper-16 .checkbox-label {
  color: #373737;
  transition: 0.375s ease;
  text-align: center;
}

.campoSelected:hover {
  color: #237e32;  
  border-bottom: 1px solid #319b4396;
  /* font-size: 13px; */
}

.btn-clear:hover{
  color: rgba(222, 18, 18, 0.852);
}

/* CSS */
.button-48 {
  appearance: none;
  background-color: #FFFFFF;
  border-width: 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  /* font-family: Clarkson,Helvetica,sans-serif; */
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 0em;
  margin: 0;
  opacity: 1;
  outline: 0;
  padding: 1.5em 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1),background-color 100ms cubic-bezier(.694, 0, 0.335, 1),color 100ms cubic-bezier(.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-48:before {
  animation: opacityFallbackOut .5s step-end forwards;
  /* backface-visibility: hidden; */
  background-color:#0aff5874;
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
  width: 100%;
}

.button-48:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

.button-48:after {
  background-color: #FFFFFF;
}

.button-48 span {
  z-index: 1;
  position: relative;
}

@media(max-width: 767px){
  .listaMovil{
    margin-bottom: 120px;
  }
}