  .inpfile{
    font-size: 13px;
    background: white;
    border-radius: 10px;
    width: 200px;
    outline: none;
  }

  ::-webkit-file-upload-button{
    color: white;
    background:  #438a5e url(https://cdn-icons-png.flaticon.com/512/3391/3391036.png) 4px center/16px no-repeat;
    padding: 3.5px;
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 0px 1px #0000002b;
    outline: none;
    padding-left: 25px;
    padding-right: 10px;
  }

  ::-webkit-file-upload-button:hover{
    background:  #206a5db6 url(https://cdn-icons-png.flaticon.com/512/3391/3391036.png) 4px center/16px no-repeat;
  }


.file-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 190px;
  /* max-width: 200%; */
  padding: 3px;
  border-radius: 3px;
  transition: 0.2s;
  margin-left: 10px;
 
}

.fake-btn {
  color: white;
  background:  #438a5e url(https://cdn-icons-png.flaticon.com/512/3391/3391036.png) 4px center/16px no-repeat;
  padding: 4px;
  padding-left: 25px;
  width: 120px;
  border: none;
  border-radius: 5px;
  flex-shrink: 0;
  margin-right: 5px;
  font-size: 12px;
  margin-left: -20px;
}

.fake-text{
  font-size: 12px;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0; 
}

/* CSS */
.button-79 {
  background:rgb(92, 157, 102);
  border: 0;
  border-radius: .375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,Helvetica,sans-serif;
  font-size: 1.125rem;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 8px;
  position: relative;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  touch-action: manipulation;
  width: 40%;
  text-align: center;
  margin: 10px;
}

.button-79:disabled {
  color: #787878;
  cursor: auto;
}

.button-79:not(:disabled):hover {
  transform: scale(1.05);
}

.button-79:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-79:focus {
  outline: 0 solid transparent;
}

.button-79:focus:before {
  border-width: .125rem;
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
}

.button-79:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-79:not(:disabled):active {
  transform: translateY(.125rem);
}

.button-78 {
  background:rgb(201, 83, 83);
  border: 0;
  border-radius: .375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,Helvetica,sans-serif;
  font-size: 1.125rem;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 8px;
  position: relative;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  touch-action: manipulation;
  width: 40%;
  text-align: center;
  margin: 10px;
}

.button-78:disabled {
  color: #787878;
  cursor: auto;
}

.button-78:not(:disabled):hover {
  transform: scale(1.05);
}

.button-78:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-78:focus {
  outline: 0 solid transparent;
}

.button-78:focus:before {
  border-width: .125rem;
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
}

.button-78:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-78:not(:disabled):active {
  transform: translateY(.125rem);
}

button:disabled{
  opacity: 0.7 !important;
    /* display: none; */
    background: #f2f2f2;
    border: 0.5px solid #d56671;
}
.btn-trash{
    color: rgba(255, 255, 255);
    background:  rgb(201, 83, 83);
    padding: 3px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: .375rem;
    flex-shrink: 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  /* max-width: 200%; */
    transition: 0.2s;
    margin-left: 192px;
    margin-top: -30px;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  font-size: 13px;
}
.checkbox-wrapper-7 .tgl {
  display: none;
}
.checkbox-wrapper-7 .tgl,
.checkbox-wrapper-7 .tgl:after,
.checkbox-wrapper-7 .tgl:before,
.checkbox-wrapper-7 .tgl *,
.checkbox-wrapper-7 .tgl *:after,
.checkbox-wrapper-7 .tgl *:before,
.checkbox-wrapper-7 .tgl + .tgl-btn {
  box-sizing: border-box;
}
.checkbox-wrapper-7 .tgl::-moz-selection,
.checkbox-wrapper-7 .tgl:after::-moz-selection,
.checkbox-wrapper-7 .tgl:before::-moz-selection,
.checkbox-wrapper-7 .tgl *::-moz-selection,
.checkbox-wrapper-7 .tgl *:after::-moz-selection,
.checkbox-wrapper-7 .tgl *:before::-moz-selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-7 .tgl::selection,
.checkbox-wrapper-7 .tgl:after::selection,
.checkbox-wrapper-7 .tgl:before::selection,
.checkbox-wrapper-7 .tgl *::selection,
.checkbox-wrapper-7 .tgl *:after::selection,
.checkbox-wrapper-7 .tgl *:before::selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::selection {
  background: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 1.8em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after,
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after {
  left: 0;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
  display: none;
}
.checkbox-wrapper-7 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-7 .tgl-ios + .tgl-btn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #e8e8e8;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn {
  background: #86d993;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}

.resultNodos{
  border: 0.5px solid #A9A9A9;
  border-radius: 5px;
  height: 33px;
  width: 60%;
  margin-bottom: 10px;
  text-align: center;
  background-color: #F5F5F5;
}

.resultNodos:hover{
  border: 0.5px solid #A9A9A9;
  border-radius: 5px;
  height: 33px;
  width: 60%;
  margin-bottom: 10px;
  text-align: center;
  background-color: #319B42;
  color: white;
  cursor:pointer; cursor: hand	
}

#search-box {
  position: relative;
  width: 45vh;
  margin: 0;
  margin-bottom: 5px;
  }
  #search-form {
  height: 31px;
  border: 1px solid #319b4252;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  }
  #search-text {
  font-size: 14px;
  color: #ddd;
  border-width: 0;
  background: transparent;
  }
  
  #search-text:focus{
    background-color: rgba(192, 192, 192, 0.197);
  }
  
  #search-box input[type="text"] {
  width: 90%;
  padding: 4px 0 11px 7px;
  padding-left: 10px;
  color: #333;
  outline: none;
  }
  #search-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 31px;
  width: 45px;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 4px;
  border-width: 0;
  background-color: rgb(92, 157, 102);
  -webkit-border-radius: 0px 10px 10px;
  -moz-border-radius: 0px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  padding: 0px 0px 2px 5px;
  }
  #search-button:hover{
    background-color:  rgba(92, 157, 102, 0.784);
    color: white;
  }
  /* CSS */
.button-38 {
  background-color:  rgb(92, 157, 102);
  border: 0;
  border-radius: 0.6rem;
  box-sizing: border-box;
  width: 8rem;
  color: white;
  font-family: "Inter var",ui-sans-serif,system-ui,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: .45rem 0.8rem;
  text-align: center;
  text-decoration: none #D1D5DB solid;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  touch-action: manipulation;
  margin-left: 0px;
}

.button-38:hover {
  background-color: rgba(92, 157, 102, 0.784);
}

.button-38:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-38:focus-visible {
  box-shadow: none;
}

@media(max-width: 767px){
  #search-box {
    position: relative;
    width: 35vh;
    margin: 0;
    margin-bottom: 5px;
    }
}